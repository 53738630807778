<template>
  <master-layout :pageTitle="$t('pageTitle.Catalog')" :noPadding="true">
    <div class="ion-padding-top">
      <ion-grid>
        <ion-row class="table-header">
          <ion-row>
            <ion-col>
              <ion-select
                v-model="clienteSelezionato"
                placeholder="Seleziona cliente..."
              >
                <ion-select-option v-for="azienda of aziende" :key="azienda.id">
                  {{ azienda.name }} {{ azienda.surname }}
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-row>
                <ion-col class="titolo"> Catalogo prodotti </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="3">Foto</ion-col>
                <ion-col size="4">Nome</ion-col>
                <ion-col size="3">Info</ion-col>
                <ion-col size="2">Prezzo </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
        <ion-row
          @dismissed="console"
          class="riga-prodotto"
          v-for="item of items"
          :key="item.code"
        >
          <ion-col @click="openModal(item)" size="3">
            <div class="miniatura-bundle">
              <img :src="item.pic" alt="" @error="handleImgError" />
            </div>
          </ion-col>
          <ion-col @click="openModal(item)" size="4">
            {{ item.name }}
          </ion-col>
          <ion-col @click="openModal(item)" size="3">
            {{ item.format }}
          </ion-col>
          <ion-col @click="openModal(item)" size="2">
            € {{ item.price.toFixed(2) }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </master-layout>
</template>

<script>
import { IonCol, IonGrid, IonRow, modalController } from "@ionic/vue";
import SingleItem from "./SingleItem.vue";
export default {
  name: "catalogo",
  components: { IonCol, IonGrid, IonRow, SingleItem },
  data() {
    return {
      aziende: [
        {
          id: 1,
          name: "Mario",
          surname: "Pagano",
          pIva: 83094275483,
          telefono: "389 45611311",
        },
        {
          id: 2,
          name: "Giorgia",
          surname: "De Luca",
          pIva: 30489263483,
          telefono: "333 38381625",
        },
        {
          id: 3,
          name: "Alice",
          surname: "Rizzo",
          pIva: 12345678912,
          telefono: "334 23877723",
        },
        {
          id: 4,
          name: "Lorenzo",
          surname: "Barbieri",
          pIva: 21987654321,
          telefono: "324 34439284",
        },
        {
          id: 5,
          name: "Andrea",
          surname: "Villa",
          pIva: 12098744738,
          telefono: "368 34923900",
        },
      ],
      items: [
        {
          code: "7890",
          name: "Via Lactea",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/09/detersione-smart__gentle-cleasing-milk-prod-960x960.jpg",
          description:
            "Primario e primordiale il latte detergente di yoUBe attraversa il mito e la leggenda, per trasportarti dritto al centro di un nutrimento elementare. Una delicatezza galattica per una pelle divina.",
          format: "200 ml",
          price: 19,
          promo: 5,
          number: 0,
        },
        {
          code: "3486",
          name: "Gioia Infinita",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/detersione-smart__glow-tonic-prod-960x960.jpg",
          description:
            "Un flusso di radioso entusiasmo inonda la voglia della tua pelle, fremente di detersione giooiosa, pronta ad accogliere tutta l’energia di un nuovo trattamento.",
          format: "200 ml",
          price: 15,
          promo: 4,
          number: 0,
        },
        {
          code: "9234",
          name: "Vita Forte",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/09/detersione-smart__micellar-water-prod-960x960.jpg",
          description:
            "Forte come la vita che ti scorre incontro, l’acqua micellare di yoUBe riconduce la tua epidermide a una condizione originale. Intensamente gentile, rimuove trucco e impurità, idrata e protegge: 3 volte in 1.",
          format: "200",
          price: 15,
          promo: 3,
          number: 0,
        },
        {
          code: "1122",
          name: "A+MORE",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/detersione-smart__purifyng-face-scrub-prod-960x960.jpg",
          description:
            "Volersi + bene è una bellezza, un atto che libera ed emoziona ogni cosa. A levare in crescendo – e lasciare il superfluo – lo scrub viso di yoUBe t’innamora di te, purificando la tua pelle con il giusto sentimento.",
          format: "150 ml",
          price: 20,
          promo: 6,
          number: 0,
        },
        {
          code: "4094",
          name: "Mia Brama",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/anti-age__eye-lip-contour-cream-prod-960x960.jpg",
          description:
            "Intorno agli occhi e al contorno labbra, nello specchio del tempo più ardente di te. Il trattamento ridensificante globale di yoUBe: la migliore espressione del tuo desiderio.",
          format: "15 ml",
          price: 35,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "L'Assoluta",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/anti-age__hydrating-face-cream-prod-960x960.jpg",
          description:
            "A suprema immagine della bellezza è la crema viso anti-age di yoUBe: un concentrato di vitalità multifunzionale. Per ritrovare, di giorno in notte, l’essenza pura della tua pelle.",
          format: "50 ml",
          price: 55,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "D'Incanto",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/anti-age__face-cerum-prod-960x960.jpg",
          description:
            "Come un istante di meraviglia, un sortilegio o un sogno, il siero di yoUBe accarezza la tua pelle e se ne prende dolcemente cura. Contrastando le rughe e  ’invecchiamento, a sfidare le regole della gravità.",
          format: "50 ml",
          price: 60,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "AnimA",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/trattamenti-speciali__defence-repair-prod-960x960.jpg",
          description:
            "Schermo allo schermo, fluido al siero, il trattamento speciale di yoUBe porta alla luce riparo, proteggendo dal photoaging e rimediando ai suoi danni. Una combinazione sinergica, a strategia complementare.",
          format: "30 + 15 ml",
          price: 59,
          promo: 5,
          number: 0,
        },
        {
          code: "7890",
          name: "Lovers",
          pic: "https://images.unsplash.com/photo-1521033719794-41049d18b8d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80",
          price: 683.63,
          number: 472,
          promo: 0,
        },
        {
          code: "3486",
          name: "Friends",
          pic: "https://images.unsplash.com/photo-1539635278303-d4002c07eae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
          price: 500.4,
          number: 246,
          promo: 0,
        },
        {
          code: "9234",
          name: "Connect",
          pic: "https://images.unsplash.com/photo-1527525443983-6e60c75fff46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1885&q=80",
          format: "200",
          price: 278.0,
          number: 164,
          promo: 0,
        },
      ],
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    console() {
      console.log("ciao mamma");
    },
    async openModal(item) {
      const modal = await modalController.create({
        component: SingleItem,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
        },
      });
      return modal.present();
    },
  },
};
</script>

<style scoped lang="scss">
ion-grid {
  font-size: 0.8rem;
  /* font-weight: bolder; */
  color: grey;
}

img {
  border-radius: 4px;
}

.titolo {
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}

.table-header ion-col {
  font-weight: bold;
}

.riga-prodotto ion-col {
  display: grid;
  place-items: center;
}

.miniatura-bundle {
  position: relative;
  width: 90%;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.miniatura-bundle:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
</style>
